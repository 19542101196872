import React from 'react';
import PortfolioTemplate from '../templates/PortfolioTemplate';
import IndexTemplate from '../templates/IndexTemplate';

export default props => {
  const { pageContext } = props;

  if (pageContext.layout === 'index') {
    return <IndexTemplate {...props} />;
  }
  if (pageContext.layout === 'portfolio') {
    return <PortfolioTemplate {...props} />;
  }

  return <PortfolioTemplate {...props} />;
};
