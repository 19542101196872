import React from 'react';
import './scss/_index.scss';
import Footer from './Footer';

const Layout = ({ children, location }) => (
  <>
    <main id={`index-wrapper`}>{children}</main>
    <Footer />
  </>
);

export default Layout;
