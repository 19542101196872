import React, { useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { graphql, StaticQuery } from 'gatsby';
import IndexLayout from '../components/index/Layout';
import config from '../../config';
import Section from '../components/index/Section';
import { Col, Container, Row } from 'reactstrap';
import HeadingSection from '../components/index/HeadingSection';
import SkillList from '../components/index/SkillList';
import MainHeader from '../components/index/MainHeader';
import Navbar from '../components/index/Navbar';
import PortfolioItem from '../components/index/PortfolioItem';
import SectionHeader from '../components/index/SectionHeader';
import Sidebar from '../components/index/Sidebar';
import SocialLink from '../components/index/SocialLink';
import HTML from '../components/index/HTML';
import { filter, groupBy, map } from 'lodash';
import Reference from '../components/index/Reference';
import favicon from '../components/index/images/polygon-logo-64x64.png';
import slugify from 'slugify';

export const IndexPage = ({ data }) => {
  const [scrollTop, setScrollTop] = useState(0);
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);

  const skills = {
    development: groupBy(
      filter(config.skills, value =>
        ['Frameworks / Libraries', 'Programming Languages'].includes(
          value.category
        )
      ),
      'category'
    ),
    devops: groupBy(
      filter(config.skills, value =>
        ['Data', 'DevOps'].includes(value.category)
      ),
      'category'
    ),
    client: groupBy(
      filter(config.skills, value => ['Client'].includes(value.category)),
      'category'
    ),
    general: groupBy(
      filter(config.skills, value => ['General'].includes(value.category)),
      'category'
    )
  };

  const handleScroll = () => {
    setScrollTop(document.documentElement.scrollTop || document.body.scrollTop);
  };

  const toggleSidebar = () => {
    setIsSidebarVisible(!isSidebarVisible);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    // componentWillUnmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  });

  return (
    <div>
      <Sidebar isOpen={isSidebarVisible} toggle={toggleSidebar} />
      <Navbar solid={scrollTop > 0} toggleSidebar={toggleSidebar} />
      <div id='intro'>
        <Section bgStars>
          <Section roundedBottom padding={Section.PADDING_LARGE}>
            <Container>
              <MainHeader
                title='Gabe Wyatt'
                subtitle='Full Stack Developer / DevOps Engineer / Senior Technical Writer'
              >
                <SocialLink icon='github' to='https://github.com/GabeStah' />
                <SocialLink
                  icon='linkedin'
                  to='https://www.linkedin.com/in/gabe-wyatt-8a9231161/'
                />
                <SocialLink icon='mail4' to='mailto:gabe@gabewyatt.com' />
              </MainHeader>
            </Container>
          </Section>
        </Section>
        <Section roundedTop padding={Section.PADDING_SMALL}>
          <Container>
            <Row>
              <Col className='text-center' lg={{ size: 8, offset: 2 }}>
                <HTML>{data.introMarkdown.html}</HTML>
              </Col>
            </Row>
          </Container>
        </Section>
      </div>
      <Section id='skillset' bgStars padding={Section.PADDING_SMALL}>
        <Container>
          <SectionHeader
            index={1}
            title='Skillset'
            description="As a technically-obsessed person, over the years I've developed projects using a wide variety of languages, technologies, and platforms."
          />
          <Row>
            <Col lg='6'>
              <HeadingSection
                icon='embed2'
                title='Development'
                size={3}
                noMargin
              >
                <HTML>{data.skillsDevelopmentMarkdown.html}</HTML>
              </HeadingSection>
            </Col>
            <Col lg='6'>
              {map(skills.development, (skills, key) => (
                <HeadingSection
                  icon={skills[0].icon}
                  title={key}
                  size={5}
                  className='mt-lg-0'
                  key={key}
                >
                  <SkillList skills={skills} />
                </HeadingSection>
              ))}
            </Col>
          </Row>
          <Row>
            <Col lg='6'>
              <HeadingSection icon='sphere' title='DevOps' size={3} noMargin>
                <HTML>{data.skillsDevOpsMarkdown.html}</HTML>
              </HeadingSection>
            </Col>
            <Col lg='6'>
              {map(skills.devops, (skills, key) => (
                <HeadingSection
                  icon={skills[0].icon}
                  title={key}
                  size={5}
                  className='mt-lg-0'
                  key={key}
                >
                  <SkillList skills={skills} />
                </HeadingSection>
              ))}
            </Col>
          </Row>
          <Row>
            <Col lg='6'>
              <HeadingSection
                icon='cog'
                title='Client Technologies'
                size={3}
                noMargin
              >
                <HTML>{data.skillsClientMarkdown.html}</HTML>
              </HeadingSection>
            </Col>
            <Col lg='6'>
              {map(skills.client, (skills, key) => (
                <HeadingSection
                  icon={skills[0].icon}
                  title={key}
                  size={5}
                  className='mt-lg-0'
                  key={key}
                >
                  <SkillList skills={skills} />
                </HeadingSection>
              ))}
            </Col>
          </Row>
          <Row>
            <Col lg='6'>
              <HeadingSection icon='mic' title='General' size={3} noMargin>
                <HTML>{data.skillsGeneralMarkdown.html}</HTML>
              </HeadingSection>
            </Col>
            <Col lg='6'>
              {map(skills.general, (skills, key) => (
                <HeadingSection
                  icon={skills[0].icon}
                  title={key}
                  size={5}
                  className='mt-lg-0'
                  key={key}
                >
                  <SkillList skills={skills} />
                </HeadingSection>
              ))}
            </Col>
          </Row>
        </Container>
      </Section>
      <Section id='portfolio' padding={Section.PADDING_SMALL}>
        <Container>
          <SectionHeader
            index={2}
            title='Portfolio'
            description="Here are a few of the many things I've worked on over the years. Below you'll find a brief project description,
            the relevant skills that were used, the published site/content, and the original project source code (where relevant)."
          />
          {data.portfolioItems.edges.map(({ node }, index) => (
            <PortfolioItem
              noMargin={index === 0}
              key={node.name}
              alt={index % 2 > 0}
              name={node.name}
              description={node.description}
              year={node.year}
              siteLink={node.siteLink}
              skillsUsed={node.skillsUsed}
              sourceCode={node.sourceCode}
              caseStudy={node.caseStudy}
              imageSizes={node.image.childImageSharp.fluid}
              id={slugify(node.name, {
                replacement: '-',
                lower: true
              })}
            />
          ))}
        </Container>
      </Section>
      <Section id='references' bgStars padding={Section.PADDING_SMALL}>
        <Container>
          <SectionHeader
            index={3}
            title='References'
            description="Don't take my word for it. Here's what people I've
                worked with have said about me."
          />
          <Row>
            {data.references.edges.map(
              ({ node: { html, frontmatter } }, index) => (
                <Col lg='6' key={frontmatter.id}>
                  <Reference
                    noMargin={index === 0 || index === 1}
                    // className={index % 2 === 1 ? 'mt-lg-0' : ''}
                    author={frontmatter.author}
                    company={frontmatter.company}
                  >
                    {html}
                  </Reference>
                </Col>
              )
            )}
          </Row>
        </Container>
      </Section>
      {/*<Section id='assurances' gray padding={Section.PADDING_SMALL}>*/}
      {/*  <Container>*/}
      {/*    <SectionHeader*/}
      {/*      index={4}*/}
      {/*      title='Assurances'*/}
      {/*      description='Meeting and exceeding the needs of my clients is of the utmost importance.*/}
      {/*      Here are many of the values and assurances I provide to everyone I work with.'*/}
      {/*    />*/}
      {/*    <Row>*/}
      {/*      <Col lg='3' sm='6'>*/}
      {/*        <HeadingSection*/}
      {/*          title='Guaranteed Deadlines'*/}
      {/*          icon='clock'*/}
      {/*          size={4}*/}
      {/*          noMargin*/}
      {/*        >*/}
      {/*          Once a project is set in stone and a deadline is agreed upon, I*/}
      {/*          will ensure the project stays on track and is completed and sent*/}
      {/*          to you prior to that deadline.*/}
      {/*        </HeadingSection>*/}
      {/*      </Col>*/}
      {/*      <Col lg='3' sm='6'>*/}
      {/*        <HeadingSection*/}
      {/*          title='Ghostwriting'*/}
      {/*          icon='users'*/}
      {/*          size={4}*/}
      {/*          className='mt-sm-0'*/}
      {/*        >*/}
      {/*          I have completed hundreds of ghostwriting projects in the past*/}
      {/*          and would love to help you by creating stellar content that can*/}
      {/*          then be attributed to you or another thought leader in your*/}
      {/*          company.*/}
      {/*        </HeadingSection>*/}
      {/*      </Col>*/}
      {/*      <Col lg='3' sm='6'>*/}
      {/*        <HeadingSection*/}
      {/*          title='Communication'*/}
      {/*          icon='bubbles2'*/}
      {/*          size={4}*/}
      {/*          className='mt-lg-0'*/}
      {/*        >*/}
      {/*          Frequent and thorough communication is a necessity for a*/}
      {/*          successful project, so I will always be available via email,*/}
      {/*          private message, or phone.*/}
      {/*        </HeadingSection>*/}
      {/*      </Col>*/}
      {/*      <Col lg='3' sm='6'>*/}
      {/*        <HeadingSection*/}
      {/*          title='Repeated Projects'*/}
      {/*          icon='loop'*/}
      {/*          size={4}*/}
      {/*          className='mt-lg-0'*/}
      {/*        >*/}
      {/*          Many of my clients have seen tremendous benefit from an ongoing*/}
      {/*          source of projects and content. I am often available and willing*/}
      {/*          to set aside enough time in the week or month to focus on*/}
      {/*          repeated projects to ensure your company's content stream never*/}
      {/*          dries up.*/}
      {/*        </HeadingSection>*/}
      {/*      </Col>*/}
      {/*      <Col lg='3' sm='6'>*/}
      {/*        <HeadingSection*/}
      {/*          title='Shape Each Project to Your Needs'*/}
      {/*          icon='tools'*/}
      {/*          size={4}*/}
      {/*        >*/}
      {/*          Every client has different needs and, therefore, each completed*/}
      {/*          project should adjust to those needs as required. My writing*/}
      {/*          always aims to match the appropriate tone, style, target*/}
      {/*          demographic, and narrative voice that your project requires.*/}
      {/*        </HeadingSection>*/}
      {/*      </Col>*/}
      {/*      <Col lg='3' sm='6'>*/}
      {/*        <HeadingSection title='SEO Consultation' icon='search' size={4}>*/}
      {/*          Even an exceptionally well-written piece may be ignored or*/}
      {/*          forgotten without proper marketing. In addition to creating*/}
      {/*          pieces that meet modern SEO standards, I also offer consultation*/}
      {/*          services to ensure the finished project will rank highly on*/}
      {/*          Google and other search engines.*/}
      {/*        </HeadingSection>*/}
      {/*      </Col>*/}
      {/*      <Col lg='3' sm='6'>*/}
      {/*        <HeadingSection title='Project Types' icon='drawer' size={4}>*/}
      {/*          I am adept and creating professional projects of many types*/}
      {/*          including API documentation, articles, blog posts, copywriting,*/}
      {/*          direct mail/email, eBooks, essays, press releases, product*/}
      {/*          descriptions, social media posts, tutorials, white papers, and*/}
      {/*          more.*/}
      {/*        </HeadingSection>*/}
      {/*      </Col>*/}
      {/*      <Col lg='3' sm='6'>*/}
      {/*        <HeadingSection*/}
      {/*          title='Competitive Rates'*/}
      {/*          icon='credit-card'*/}
      {/*          size={4}*/}
      {/*        >*/}
      {/*          While you may opt to find a technical writer/developer through a*/}
      {/*          recruitment service, as the client you’ll invariably pay the*/}
      {/*          extra overhead that entails. By working directly with an*/}
      {/*          experienced writer like myself, you’ll save money while having a*/}
      {/*          direct line of communication to the creator.*/}
      {/*        </HeadingSection>*/}
      {/*      </Col>*/}
      {/*    </Row>*/}
      {/*  </Container>*/}
      {/*</Section>*/}
    </div>
  );
};

export const IndexTemplate = props => {
  return (
    <StaticQuery
      query={graphql`
        fragment PortfolioItems on Query {
          portfolioItems: allPortfolioYaml {
            edges {
              node {
                name
                description
                #                caseStudy
                siteLink
                sourceCode
                #                year
                skillsUsed
                image {
                  childImageSharp {
                    fluid(maxWidth: 700) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
        fragment References on Query {
          references: allMarkdownRemark(
            filter: { frontmatter: { id: { glob: "reference-*" } } }
            sort: { order: ASC, fields: [frontmatter___index] }
          ) {
            edges {
              node {
                html
                frontmatter {
                  id
                  author {
                    name
                    position
                    avatar {
                      childImageSharp {
                        fixed(width: 64, height: 64) {
                          ...GatsbyImageSharpFixed
                        }
                      }
                    }
                  }
                  company {
                    name
                    url
                  }
                }
              }
            }
          }
        }
        query IndexQuery {
          ...PortfolioItems
          ...References
          introMarkdown: markdownRemark(
            frontmatter: { id: { eq: "content-intro" } }
          ) {
            html
          }
          skillsClientMarkdown: markdownRemark(
            frontmatter: { id: { eq: "content-skills-client" } }
          ) {
            html
          }
          skillsGeneralMarkdown: markdownRemark(
            frontmatter: { id: { eq: "content-skills-general" } }
          ) {
            html
          }
          skillsDevOpsMarkdown: markdownRemark(
            frontmatter: { id: { eq: "content-skills-devops" } }
          ) {
            html
          }
          skillsDevelopmentMarkdown: markdownRemark(
            frontmatter: { id: { eq: "content-skills-development" } }
          ) {
            html
          }
        }
      `}
      render={data => {
        const { scrollTop, toggleSidebar } = data;
        const { title, description, canonicalUrl } = config.siteMetadata;
        return (
          <IndexLayout {...props}>
            <Helmet>
              {title ? <title>{title}</title> : null}
              {title ? <meta name='title' content={title} /> : null}
              {description ? (
                <meta name='description' content={description} />
              ) : null}
              {title ? <meta property='og:title' content={title} /> : null}
              {description ? (
                <meta property='og:description' content={description} />
              ) : null}
              {title ? <meta property='twitter:title' content={title} /> : null}
              {description ? (
                <meta property='twitter:description' content={description} />
              ) : null}
              <link rel='canonical' href={canonicalUrl} />
              <link rel='shortcut icon' type='image/png' href={favicon} />
            </Helmet>
            <IndexPage
              data={data}
              scrollTop={scrollTop}
              toggleSidebar={toggleSidebar}
              {...props}
            />
          </IndexLayout>
        );
      }}
    />
  );
};

export default IndexTemplate;
