import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'reactstrap';

import './scss/PortfolioItem.scss';
import Heading from './Heading';
import Icon from './Icon';
import SkillList from './SkillList';

const PortfolioItem = ({
  alt,
  caseStudy,
  description,
  imageSizes,
  name,
  noMargin,
  siteLink,
  skillsUsed,
  sourceCode,
  year,
  id
}) => (
  <Row className={`PortfolioItem ${noMargin ? '' : 'mt-5'}`} id={id}>
    <Col lg={{ size: 6, order: alt ? 2 : undefined }}>
      <div className='PortfolioItem__image-container'>
        <Img
          className='PortfolioItem__image img-fluid mb-4 mb-lg-0'
          alt={`${name} screenshot`}
          fluid={imageSizes}
        />
      </div>
    </Col>
    <Col lg='6'>
      <div className='PortfolioItem__header'>
        <Heading className='PortfolioItem__title' size={3}>
          {name}
          <a className='anchor' aria-hidden='true' href={`#${id}`}>
            <svg
              className='octicon octicon-link'
              viewBox='0 0 16 16'
              version='1.1'
              width='16'
              height='16'
              aria-hidden='true'
            >
              <path
                fillRule='evenodd'
                d='M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z'
              ></path>
            </svg>
          </a>
        </Heading>
        {year ? (
          <Heading
            size={3}
            className='PortfolioItem__date text-primary font-weight-medium'
          >
            <span className='PortfolioItem__date-mdash'>{'—'}</span>
            {year}
          </Heading>
        ) : (
          ''
        )}
      </div>
      <div dangerouslySetInnerHTML={{ __html: description }} />
      <div className='mt-4'>
        <SkillList skills={skillsUsed} />
      </div>
      {(caseStudy || siteLink || sourceCode) && (
        <ul className='list-inline'>
          {[
            { link: siteLink, name: 'View site', icon: 'sphere' },
            { link: caseStudy, name: 'Case study', icon: 'profile' },
            { link: sourceCode, name: 'Source code', icon: 'embed2' }
          ].map(
            item =>
              item.link && (
                <li className='list-inline-item mr-4 mt-2' key={item.name}>
                  <a href={item.link} target='_blank' rel='noopener noreferrer'>
                    <Icon name={item.icon} /> {item.name}
                  </a>
                </li>
              )
          )}
        </ul>
      )}
    </Col>
  </Row>
);

PortfolioItem.propTypes = {
  alt: PropTypes.bool,
  caseStudy: PropTypes.string,
  description: PropTypes.string.isRequired,
  imageSizes: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  noMargin: PropTypes.bool,
  siteLink: PropTypes.string,
  skillsUsed: PropTypes.arrayOf(PropTypes.string).isRequired,
  sourceCode: PropTypes.string,
  year: PropTypes.number
};

PortfolioItem.defaultProps = {
  alt: false,
  caseStudy: undefined,
  noMargin: false,
  siteLink: undefined,
  sourceCode: undefined,
  year: undefined
};

export default PortfolioItem;
