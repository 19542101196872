import React from 'react';

import Button from './Button';
import config from '../../../config';

const { email } = config.info;

const ContactButton = props => (
  <Button
    color='primary'
    icon='mail'
    tag='a'
    href={`mailto:${email}`}
    {...props}
  >
    Email Me
  </Button>
);

export default ContactButton;
