import PropTypes from 'prop-types';
import React from 'react';
import { slide as Menu } from 'react-burger-menu';

import AnchorLink from './AnchorLink';
import NavScrollspy from './NavScrollspy';
import config from '../../../config';
import './scss/Sidebar.scss';
import { Link } from 'gatsby';
import { NavItem } from 'reactstrap';

const { email } = config.info;
const { pageContentId, pageContentContainerId } = config.sidebar;

const onStateChange = (isOpen, toggle) => newState => {
  if (isOpen !== newState.isOpen) {
    toggle();
  }
};

const Sidebar = ({ isOpen, toggle }) => (
  <Menu
    className='Sidebar'
    isOpen={isOpen}
    pageWrapId={pageContentId}
    outerContainerId={pageContentContainerId}
    onStateChange={onStateChange(isOpen, toggle)}
    customBurgerIcon={false}
  >
    <NavScrollspy
      mapItems={({ text, href }) =>
        href.startsWith('#') ? (
          <AnchorLink
            className='Sidebar__link d-block'
            href={href}
            key={text}
            onClick={toggle}
          >
            {text}
          </AnchorLink>
        ) : (
          <Link className='Sidebar__link d-block' to={href} key={text}>
            {text}
          </Link>
        )
      }
    />
    <a className='Sidebar__link' href={`mailto:${email}`}>
      Email Me
    </a>
  </Menu>
);

Sidebar.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func.isRequired
};

Sidebar.defaultProps = {
  isOpen: false
};

export default Sidebar;
