import PropTypes from 'prop-types';
import React from 'react';

import Scrollspy from './Scrollspy';
import config from '../../../config';

const { links } = config.header.nav;

const NavScrollspy = ({ className, mapItems }) => (
  <Scrollspy className={className} items={links.map(link => link.href)}>
    {links.map(mapItems)}
  </Scrollspy>
);

NavScrollspy.propTypes = {
  className: PropTypes.string,
  mapItems: PropTypes.func.isRequired
};

NavScrollspy.defaultProps = {
  className: undefined
};

export default NavScrollspy;
