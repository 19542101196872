import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import React from 'react';

import Heading from './Heading';
import HTML from './HTML';
import Link from '../link';
import './scss/Reference.scss';

export const addQuotesToHtml = html => {
  const parser = new DOMParser();
  const document = parser.parseFromString(html, 'text/html');

  const firstElement = document.body.childNodes[0];
  firstElement.innerHTML = `“${firstElement.innerHTML}`;

  const lastElementIndex = document.body.childNodes.length - 1;
  const lastElement = document.body.childNodes[lastElementIndex];
  lastElement.innerHTML = `${lastElement.innerHTML}”`;

  return document.body.innerHTML;
};

class Reference extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      children: props.children
    };
  }

  componentDidMount() {
    const { children } = this.props;
    this.setState({
      children: addQuotesToHtml(children)
    });
  }

  render() {
    const { author, company, className, noMargin } = this.props;
    const { name: authorName, position: authorPosition } = author;
    const authorAvatar = author.avatar.childImageSharp.fixed;
    const { name: companyName, url: companyUrl } = company;
    const { children } = this.state;

    return (
      <div className={`${className} ${noMargin ? '' : 'mt-5'}`}>
        <div className='Reference'>
          <div className='Reference__header'>
            <div className='d-flex align-items-center'>
              <Img
                alt={authorName}
                className='Reference__author-avatar rounded-circle mr-3'
                resolutions={authorAvatar}
              />
              <div>
                <Heading size={4}>{authorName}</Heading>
                <Heading size={6} light className='text-secondary'>
                  {authorPosition}
                </Heading>
                <Heading size={6} light className={'text-secondary'}>
                  <Link
                    to={companyUrl}
                    target={'_blank'}
                    rel={'noopener noreferrer'}
                  >
                    {companyName}
                  </Link>
                </Heading>
              </div>
            </div>
          </div>
          <div className='Reference__body'>
            <HTML>{children}</HTML>
          </div>
        </div>
      </div>
    );
  }
}

Reference.propTypes = {
  author: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,
  children: PropTypes.string.isRequired,
  className: PropTypes.string,
  noMargin: PropTypes.bool
};

Reference.defaultProps = {
  className: '',
  noMargin: false
};

export default Reference;
