import React from 'react';
import { Container } from 'reactstrap';

import ContactButton from './ContactButton';
import Section from './Section';
import SocialLink from './SocialLink';
import MainHeader from './MainHeader';

const Footer = () => (
  <footer className='text-center'>
    <Section padding={Section.PADDING_SMALL}>
      <Container>
        <h2>Want to get in touch?</h2>
        <p>Send me an email and I'll get back to you soon.</p>
        {/*<SocialLink icon='github' to='https://github.com/GabeStah' />*/}
        {/*<SocialLink*/}
        {/*  icon='linkedin'*/}
        {/*  to='https://www.linkedin.com/in/gabe-wyatt-8a9231161/'*/}
        {/*/>*/}
        <ContactButton className='mt-4' size='lg' />
      </Container>
    </Section>
  </footer>
);

export default Footer;
