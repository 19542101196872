import PropTypes from 'prop-types';
import React from 'react';
import { sortBy } from 'lodash';
import { Link } from 'gatsby';
import styled from '@emotion/styled';

import './scss/SkillList.scss';

const OtherLink = styled('a')`
  // :after {
  //   font-family: 'icomoon';
  //   content: ' \\f08e';
  // }
`;

const SkillList = ({ skills, isDark = false }) => (
  <div className='SkillList'>
    {sortBy(skills, 'name').map(skill => {
      if (typeof skill === 'string') skill = { name: skill };
      return (
        <code
          className={isDark ? 'SkillList__item-dark' : 'SkillList__item'}
          key={skill.name}
        >
          {skill.url ? (
            <a className={'external'} target='_blank' href={skill.url}>
              {skill.name}
            </a>
          ) : (
            skill.name
          )}
        </code>
      );
    })}
  </div>
);

SkillList.propTypes = {
  skills: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object).isRequired,
    PropTypes.arrayOf(PropTypes.string).isRequired
  ])
};

export default SkillList;
