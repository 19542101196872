import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'gatsby';
import { Collapse, Container, Navbar as BSNavbar, NavItem } from 'reactstrap';

import AnchorLink from './AnchorLink';
import ContactButton from './ContactButton';
import NavScrollspy from './NavScrollspy';
import Icon from './Icon';
import logo from './images/polygon-logo.svg';
import './scss/Navbar.scss';

const Navbar = ({ solid, toggleSidebar }) => {
  const className = classNames('Navbar', {
    'Navbar--solid': solid
  });

  return (
    <BSNavbar className={className} expand='lg' fixed='top'>
      <Container>
        <AnchorLink href='#intro' className='navbar-brand mr-0'>
          <img className={'img-responsive'} src={logo} alt={'Logo'} />
        </AnchorLink>
        <button
          className='navbar-toggler'
          type='button'
          onClick={toggleSidebar}
        >
          <div className='d-flex align-items-center'>
            <Icon name='menu2' />
          </div>
        </button>
        <Collapse navbar>
          <NavScrollspy
            className='navbar-nav pl-0'
            mapItems={({ text, href }) => (
              <NavItem className={text === 'Intro' ? 'd-none' : ''} key={text}>
                {href.startsWith('#') ? (
                  <AnchorLink className='nav-link' href={href}>
                    {text}
                  </AnchorLink>
                ) : (
                  <Link to={href} className={'nav-link'}>
                    {text}
                  </Link>
                )}
              </NavItem>
            )}
          />
          <ContactButton />
        </Collapse>
      </Container>
    </BSNavbar>
  );
};

Navbar.propTypes = {
  solid: PropTypes.bool,
  toggleSidebar: PropTypes.func.isRequired
};

Navbar.defaultProps = {
  solid: false
};

export default Navbar;
