const skills = require('./config-skills');

const config = {
  gatsby: {
    pathPrefix: '/',
    portfolioPath: '/portfolio',
    siteUrl: 'https://localhost:8000',
    gaTrackingId: null
  },
  header: {
    logo:
      '',
    logoLink: '/',
    title: 'Gabe Wyatt | Full Stack Developer / DevOps Engineer / Senior Technical Writer',
    githubUrl: 'https://github.com/gabestah',
    helpUrl: '',
    tweetText: '',
    links: [{ text: '', link: '' }],
    search: {
      enabled: false,
      indexName: 'learn-hasura-test',
      algoliaAppId: process.env.GATSBY_ALGOLIA_APP_ID,
      algoliaSearchKey: process.env.GATSBY_ALGOLIA_SEARCH_KEY,
      algoliaAdminKey: process.env.ALGOLIA_ADMIN_KEY
    },
    nav: {
      links: [
        {
          text: 'Intro',
          href: '#intro'
        },
        {
          text: 'Skillset',
          href: '#skillset'
        },
        {
          text: 'Portfolio',
          href: '#portfolio'
        },
        {
          text: 'References',
          href: '#references'
        },
      ]
    }
  },
  sidebar: {
    forcedNavOrder: ['/introduction', '/codeblock'],
    links: [
      { text: 'Ping Publications', link: 'https://pingpublications.com' }
    ],
    frontline: false,
    ignoreIndex: true,
    pageContentId: 'page-content',
    pageContentContainerId: 'page-content-container'
  },
  siteMetadata: {
    title: 'Gabe Wyatt | Full Stack Developer / DevOps Engineer / Senior Technical Writer',
    description: 'Technical portfolio for Gabe Wyatt.',
    canonicalUrl: 'https://gabewyatt.com',
    ogImage: null,
    docsLocation:
      'https://github.com/gabestah/portfolio.gabewyatt.com/tree/master/content',
    favicon: ''
  },
  info: {
    email: 'gabe@gabewyatt.com'
  },
  skills
};

module.exports = config;
