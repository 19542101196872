const skills = [
  {
    category: 'Data',
    name: 'GraphQL',
    icon: 'database',
    url: 'https://graphql.org/'
  },
  {
    category: 'Data',
    name: 'MongoDB',
    icon: 'database',
    url: 'https://www.mongodb.com/'
  },
  {
    category: 'Data',
    name: 'MySQL',
    icon: 'database',
    url: 'https://www.mysql.com/'
  },
  {
    category: 'Data',
    name: 'PostgreSQL',
    icon: 'database',
    url: 'https://www.postgresql.org/'
  },
  {
    category: 'Data',
    name: 'Redis',
    icon: 'database',
    url: 'https://redis.io/'
  },
  {category: 'Data', name: 'SQL', icon: 'database'},
  {
    category: 'DevOps',
    name: 'Amazon Aurora',
    icon: 'cloud-check',
    url: 'https://aws.amazon.com/rds/aurora/'
  },
  {
    category: 'DevOps',
    name: 'Amazon CloudFormation',
    icon: 'cloud-check',
    url: 'https://aws.amazon.com/cloudformation/'
  },
  {
    category: 'DevOps',
    name: 'Amazon CloudFront',
    icon: 'cloud-check',
    url: 'https://aws.amazon.com/cloudfront/'
  },
  {
    category: 'DevOps',
    name: 'Amazon CloudWatch',
    icon: 'cloud-check',
    url: 'https://aws.amazon.com/cloudwatch/'
  },
  {
    category: 'DevOps',
    name: 'Amazon EBS',
    icon: 'cloud-check',
    url: 'https://aws.amazon.com/ebs/'
  },
  {
    category: 'DevOps',
    name: 'Amazon EC2',
    icon: 'cloud-check',
    url: 'https://aws.amazon.com/ec2/'
  },
  {
    category: 'DevOps',
    name: 'Amazon IAM',
    icon: 'cloud-check',
    url: 'https://aws.amazon.com/iam/'
  },
  {
    category: 'DevOps',
    name: 'Amazon Lambda',
    icon: 'cloud-check',
    url: 'https://aws.amazon.com/lambda/'
  },
  {
    category: 'DevOps',
    name: 'Amazon RDS',
    icon: 'cloud-check',
    url: 'https://aws.amazon.com/rds/'
  },
  {
    category: 'DevOps',
    name: 'Amazon Route53',
    icon: 'cloud-check',
    url: 'https://aws.amazon.com/route53/'
  },
  {
    category: 'DevOps',
    name: 'Amazon S3',
    icon: 'cloud-check',
    url: 'https://aws.amazon.com/s3/'
  },
  {
    category: 'DevOps',
    name: 'Amazon VPC',
    icon: 'cloud-check',
    url: 'https://aws.amazon.com/vpc/'
  },
  {
    category: 'DevOps',
    name: 'Apache',
    icon: 'cloud-check',
    url: 'https://www.apache.org/'
  },
  {
    category: 'DevOps',
    name: 'GitHub',
    icon: 'cloud-check',
    url: 'https://github.com/'
  },
  {
    category: 'DevOps',
    name: 'GitLab',
    icon: 'cloud-check',
    url: 'https://gitlab.com/'
  },
  {
    category: 'DevOps',
    name: 'Nginx',
    icon: 'cloud-check',
    url: 'https://www.nginx.com/'
  },
  {
    category: 'DevOps',
    name: 'Node.js',
    icon: 'cloud-check',
    url: 'https://nodejs.org/'
  },
  {
    category: 'Frameworks / Libraries',
    name: 'Django',
    icon: 'react',
    url: 'https://www.djangoproject.com/'
  },
  {
    category: 'Frameworks / Libraries',
    name: 'Gatsby',
    icon: 'react',
    url: 'https://www.gatsbyjs.org/'
  },
  {
    category: 'Frameworks / Libraries',
    name: 'Hugo',
    icon: 'react',
    url: 'https://gohugo.io/'
  },
  {
    category: 'Frameworks / Libraries',
    name: 'Jekyll',
    icon: 'react',
    url: 'https://jekyllrb.com/'
  },
  {
    category: 'Frameworks / Libraries',
    name: 'jQuery',
    icon: 'react',
    url: 'https://jquery.com/'
  },
  {
    category: 'Frameworks / Libraries',
    name: 'Node.js',
    icon: 'react',
    url: 'https://nodejs.org/en/'
  },
  {
    category: 'Frameworks / Libraries',
    name: 'React',
    icon: 'react',
    url: 'https://reactjs.org/'
  },
  {
    category: 'Frameworks / Libraries',
    name: 'Sass',
    icon: 'react',
    url: 'https://sass-lang.com/'
  },
  {
    category: 'Frameworks / Libraries',
    name: 'Vue',
    icon: 'react',
    url: 'https://vuejs.org/'
  },
  {
    category: 'General',
    name: 'Adobe After Effects',
    icon: 'pencil2',
    url: 'https://www.adobe.com/products/aftereffects.html'
  },
  {
    category: 'General',
    name: 'Adobe Illustrator',
    icon: 'pencil2',
    url: 'https://www.adobe.com/products/illustrator.html'
  },
  {
    category: 'General',
    name: 'Adobe Photoshop',
    icon: 'pencil2',
    url: 'https://www.adobe.com/products/photoshop.html'
  },
  {
    category: 'General',
    name: 'Adobe Premiere Pro',
    icon: 'pencil2',
    url: 'https://www.adobe.com/products/premiere.html'
  },
  {category: 'General', name: 'Copy Editing', icon: 'pencil2'},
  {category: 'General', name: 'Copywriting', icon: 'pencil2'},
  {
    category: 'General',
    name: 'Git',
    icon: 'pencil2',
    url: 'https://git-scm.com/'
  },
  {category: 'General', name: 'SSH', icon: 'pencil2'},
  {category: 'General', name: 'Technical Writing', icon: 'pencil2'},
  {
    category: 'Programming Languages',
    name: 'C#',
    icon: 'embed2',
    url: 'https://docs.microsoft.com/en-us/dotnet/csharp/'
  },
  {category: 'Programming Languages', name: 'CSS', icon: 'embed2'},
  {
    category: 'Programming Languages',
    name: 'Golang',
    icon: 'embed2',
    url: 'https://golang.org/'
  },
  {category: 'Programming Languages', name: 'HTML', icon: 'embed2'},
  {category: 'Programming Languages', name: 'Java', icon: 'embed2'},
  {category: 'Programming Languages', name: 'JavaScript', icon: 'embed2'},
  {
    category: 'Programming Languages',
    name: 'Kotlin',
    icon: 'embed2',
    url: 'https://kotlinlang.org/'
  },
  {
    category: 'Programming Languages',
    name: 'Lua',
    icon: 'embed2',
    url: 'https://www.lua.org/'
  },
  {
    category: 'Programming Languages',
    name: 'PHP',
    icon: 'embed2',
    url: 'https://www.php.net/'
  },
  {
    category: 'Programming Languages',
    name: 'Python',
    icon: 'embed2',
    url: 'https://www.python.org/'
  },
  {
    category: 'Programming Languages',
    name: 'Ruby',
    icon: 'embed2',
    url: 'https://www.ruby-lang.org/en/'
  },
  {
    category: 'Programming Languages',
    name: 'Ruby on Rails',
    icon: 'embed2',
    url: 'https://rubyonrails.org/'
  },
  {
    category: 'Programming Languages',
    name: 'TypeScript',
    icon: 'embed2',
    url: 'https://www.typescriptlang.org/'
  },
  {
    category: 'Client',
    name: 'Dgraph',
    icon: 'cogs',
    url: 'https://dgraph.io/',
    description: 'Distributed graph database'
  },
  {
    category: 'Client',
    name: 'Gremlin',
    icon: 'cogs',
    url: 'https://www.gremlin.com/',
    description: 'Chaos engineering platform'
  },
  {
    category: 'Client',
    name: 'Airbrake',
    icon: 'cogs',
    url: 'https://airbrake.io/',
    description: 'Full-stack application monitoring'
  },
  {
    category: 'Client',
    name: 'Test.io',
    icon: 'cogs',
    url: 'https://test.io/',
    description: 'QA testing service software'
  },
  {
    category: 'Client',
    name: 'Chartio',
    icon: 'cogs',
    url: 'https://chartio.com/',
    description: 'Cloud-based data analytics'
  }
];

module.exports = skills;
